import React, { useEffect, useState } from "react";
import { Caption1, Spinner, Subtitle2, TableCellLayout, TableColumnDefinition, createTableColumn } from "@fluentui/react-components";
import ErrorBoundary from "./ErrorBoundary";
import { User } from "@microsoft/microsoft-graph-types";
import { IOperationRequestBase, ITeamsPolicy, ITeamsPowerShellTask, TaskStatus, TeamsPolicyType, teamsPowerShellTaskSchema, TeamsPowerShellTaskType } from "@symity-hub/types";
import { sharedHorizontalMediumGapWrapFlexStyles, sharedHorizontalSmallGapFlexStyles, sharedVerticalMediumGapFlexStyles } from "../styles/styles";
import { fetcher } from "../modules/api";
import { useMsal } from "@azure/msal-react";
import useSWR from "swr";
import { CustomAlert } from "./CustomAlert";
import { OperationDialog } from "./OperationDialog";
import { CustomEditDrawer } from "./CustomEditDrawer";
import { CustomDropdown } from "./CustomDropdown";
import { OptionOnSelectData } from "@fluentui/react-combobox";
import { BasicDataGrid } from "./BasicDataGrid";
import { ZodError } from "zod";
import { toTitleCase } from "../modules/string";

interface IAssignUserPoliciesPanelProps {
    users: User[];
    assignedUserPolicies?: ITeamsPolicy[];
    open: boolean;
    setOpen: (open: boolean) => void;
    onDismiss: () => void;
}

export const AssignUserPoliciesPanel: React.FunctionComponent<IAssignUserPoliciesPanelProps> = (props) => {

    const msalContext = useMsal();

    const [tasks, setTasks] = useState<ITeamsPowerShellTask[]>([]);
    const [teamsPolicies, setTeamsPolicies] = useState<ITeamsPolicy[]>([]);
    const [operationId, setOperationId] = useState<string>();
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [validationError, setValidationError] = useState<ZodError>();

    const columns: TableColumnDefinition<User>[] = [
        createTableColumn<User>({
            columnId: "displayName",
            renderHeaderCell: () => {
                return "Name";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        {item.displayName}
                    </TableCellLayout>
                );
            }
        }),
        createTableColumn<User>({
            columnId: "userPrincipalName",
            renderHeaderCell: () => {
                return "Username";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        {item.userPrincipalName}
                    </TableCellLayout>
                );
            }
        })
    ];

    // Get Teams policies from API for Tenant (not user specific)
    const { error, mutate } = useSWR<ITeamsPolicy[]>(props.users.length > 0 && props.open ? [`/api/teams/policies`, msalContext, "GET"] : null, fetcher,
        {
            revalidateIfStale: false,
            revalidateOnFocus: false,
            onSuccess: (data) => {
                // Loop through policies and add any user specific policies already assigned
                const policies = data.map((policy) => {
                    // Check if policy is already assigned to user
                    const assignedPolicy = props.assignedUserPolicies?.find((userPolicy) => userPolicy.policyType === policy.policyType);
                    if (assignedPolicy) {
                        policy.currentPolicyName = assignedPolicy.currentPolicyName;
                    }
                    return policy;
                });
                setTeamsPolicies(policies);
            }
        }
    );

    const onSave = async () => {
        if (tasks.length > 0) {
            // Validate schema
            setValidationError(undefined);
            const validTasks = await Promise.all(tasks.map(async (task) => {
                return await teamsPowerShellTaskSchema.parseAsync(task)
                    .then(async () => {
                        return task;
                    })
            }))
                .catch((error: ZodError) => {
                    console.error(error.errors);
                    setValidationError(error);
                });
            if (validTasks && validTasks.length > 0 && !validationError) {
                // Create operation request
                // Filter out void tasks
                const operationRequest: IOperationRequestBase = {
                    tasks: validTasks
                }
                // Send request
                await fetcher(["/api/teams/policies/assign", msalContext, "POST", undefined, operationRequest])
                    .then((data) => {
                        // If successful, set the operation ID and open the dialog
                        if (data.operationId) {
                            setOperationId(data.operationId);
                            setDialogOpen(true);
                        }
                    })
            }
        }
    }

    const onPolicyChange = (policyType: TeamsPolicyType, option: OptionOnSelectData) => {
        const optionValue = option?.optionValue?.toString();
        if (optionValue && props.users.length > 0) {
            // Set current value of dropdown to new option
            setTeamsPolicies((prevTeamsPolicies) => {
                return prevTeamsPolicies.map((policy) => {
                    if (policy.policyType === policyType) {
                        return {
                            ...policy,
                            currentPolicyName: optionValue
                        };
                    }
                    return policy;
                });
            });

            // Check if its the same as the data value (default), in that case remove it from the array?
            const originalPolicy = teamsPolicies?.find((policy) => policy.policyType === policyType);
            if (originalPolicy?.currentPolicyName === optionValue) {
                // Remove from tasks
                setTasks((prevTasks) => prevTasks.filter((task) => task.batchPolicyAssignment?.policyType !== policyType));
            } else {
                // Add to tasks
                setTasks((prevTasks) => {
                    const newTasks = prevTasks.filter((task: ITeamsPowerShellTask) => task.batchPolicyAssignment?.policyType !== policyType);
                    newTasks.push({
                        name: `Assign '${optionValue}' ${toTitleCase(policyType)} to ${props.users.length} user(s)`,
                        type: TeamsPowerShellTaskType.BatchPolicyAssignment,
                        batchPolicyAssignment: {
                            policyType: policyType,
                            policyName: optionValue,
                            userIds: props.users.map(user => user.id as string)
                        }
                    });
                    return newTasks;
                });
            }
        }
    }

    // Refresh data when panel is opened
    useEffect(() => {
        if (props.open) {
            // TODO - Fix this being called twice
            mutate();
        }
    }, [mutate, props.open]);

    const onDismiss = () => {
        // Reset state
        props.onDismiss();
        setTasks([]);
        setTeamsPolicies([]);
        setOperationId(undefined);
    }

    const onDismissOperationDialog = () => {
        setDialogOpen(false);
        setOperationId(undefined);
        onDismiss();
    }

    return (
        <CustomEditDrawer
            open={props.open}
            onDismiss={onDismiss}
            onSave={onSave}
            headerText="Assign policies"
            validationError={validationError}
            size="large"
        >
            <ErrorBoundary>
                <div className={sharedVerticalMediumGapFlexStyles().root}>
                    {error && (<CustomAlert text={error} type={TaskStatus.Error} id="assignUserPolicyPanel" />)}
                    {props.users.length > 0 && (
                        <BasicDataGrid
                            columns={columns}
                            items={props.users}
                        />
                    )}
                    <Subtitle2>Policies</Subtitle2>
                    <Caption1>Select the policies to assign to the selected user(s). A &rsquo;Global&rsquo; policy is an org-wide default policy.</Caption1>
                    {teamsPolicies.length === 0 && (<Spinner size="large" />)}
                    {teamsPolicies && (
                        <div className={sharedHorizontalMediumGapWrapFlexStyles().root}>
                            {teamsPolicies.map((policy: ITeamsPolicy) => {
                                return (
                                    <div
                                        className={sharedHorizontalSmallGapFlexStyles().root}
                                        key={policy.policyType}
                                    >
                                        <CustomDropdown
                                            key={policy.policyType}
                                            id={policy.policyType}
                                            selectedOptions={[policy.currentPolicyName]}
                                            value={policy.currentPolicyName}
                                            label={policy.displayName}
                                            options={policy.policies.map((policyName: string) => { return { key: policyName, text: policyName } })}
                                            onOptionSelect={(_, data) => {
                                                onPolicyChange(policy.policyType, data);
                                            }}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    )}{
                        // Pending changes/tasks
                        tasks.length > 0 && (
                            <div className={sharedVerticalMediumGapFlexStyles().root}>
                                <Subtitle2>Pending changes</Subtitle2>
                                <Caption1>Review the changes to be made before saving.</Caption1>
                                <BasicDataGrid
                                    columns={[
                                        createTableColumn<ITeamsPowerShellTask>({
                                            columnId: "name",
                                            renderHeaderCell: () => {
                                                return "Task";
                                            },
                                            renderCell: (item) => {
                                                return (
                                                    <TableCellLayout>
                                                        {item.name}
                                                    </TableCellLayout>
                                                );
                                            }
                                        })
                                    ]}
                                    items={tasks}
                                    size="small"
                                    hideHeader
                                />
                            </div>
                        )
                    }
                    {operationId && (
                        <OperationDialog
                            open={dialogOpen}
                            onDismiss={onDismissOperationDialog}
                            operationId={operationId}
                        />
                    )}
                </div>
            </ErrorBoundary>
        </CustomEditDrawer>
    );
};
